<template>
    <div>
        <v-card>
            <v-card-title>
                <v-icon color="primary"> mdi-road-variant </v-icon>
                <span class="pl-2">Lines</span>
                <v-spacer></v-spacer>
                <create-button @create="createEditRoute"></create-button>
                <activation-tool-tip model="routes"></activation-tool-tip>
            </v-card-title>
            <v-data-table
                item-key="name"
                :loading="isLoading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="routes"
                :search="search"
            >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                    ></v-text-field>
                </template>

                <template v-slot:item.created_at="{ item }">
                    <small>{{ item.created_at | moment("LL") }}</small> -
                    <small class="text-muted">{{
                        item.created_at | moment("LT")
                    }}</small>
                </template>
                <template v-slot:item.color="{ item }">
                    <v-chip :color="item.color" dark class="white--text">
                        &nbsp;&nbsp;
                    </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="viewRoute(item)">
                        mdi-eye
                    </v-icon>
                    <v-icon small class="mr-2" @click="createEditRoute(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteRoute(item, routes.indexOf(item))"
                    >
                        mdi-delete
                    </v-icon>
                    <v-btn
                        small
                        color="primary"
                        @click="showBuses(item)"
                        class="ml-2"
                    >
                        Buses
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import ActivationToolTip from "@/components/ActivationToolTip";
import CreateButton from "@/components/CreateButton";
import auth from "@/services/AuthService";
export default {
    components: {
        ActivationToolTip,
        CreateButton,
    },
    data() {
        return {
            routes: [],
            isLoading: false,
            search: "",
            headers: [
                { text: "ID", value: "id", align: "start", filterable: false },
                { text: "Name", value: "name" },
                { text: "Color", value: "color" },
                { text: "Buses", value: "buses_count" },
                { text: "Stops", value: "stops_count" },
                { text: "Actions", value: "actions", sortable: false },
            ],
        };
    },
    mounted() {
        this.loadRoutes();
    },
    methods: {
        showBuses(route) {
            this.$router.push({
                name: "buses-line",
                params: { line_id: route.id, line_title: route.name, line_color: route.color },
            });
        },
        loadRoutes() {
            this.isLoading = true;
            this.routes = [];
            axios
                .get(`/routes/all`)
                .then((response) => {
                    this.routes = response.data;
                })
                .catch((error) => {
                    this.$notify({
                        title: "Error",
                        text: "Error while retrieving routes",
                        type: "error",
                    });
                    console.log(error);
                    auth.checkError(
                        error.response.data.message,
                        this.$router,
                        this.$swal
                    );
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
        createEditRoute(route = null) {
            const basicColors = [
                "#FF0000",
                "#00FF00",
                "#0000FF",
                "#FFFF00",
                "#FF00FF",
                "#00FFFF",
                "#000000",
                "#FFFFFF",
            ];
            let selectedColor = "#FF0000"; // Default color
            selectedColor = route ? route.color : selectedColor;
            this.$swal
                .fire({
                    title: route ? "Edit Line" : "Create Line",
                    html: `
        <div style="text-align: left;">
          <label for="routeName" style="display: block; font-weight: bold; margin-bottom: 5px;">Line title</label>
          <input id="routeName" type="text" class="swal2-input" placeholder="Enter line title" value="${
              route ? route.name : ""
          }" required>

          <label style="display: block; font-weight: bold; margin: 15px 0 5px;">Color</label>
          <div id="color-options" style="display: flex; gap: 5px; flex-wrap: wrap;">
            ${basicColors
                .map(
                    (color) => `
                <div class="color-option"
                     style="background: ${color}; width: 30px; height: 30px; border-radius: 5px; cursor: pointer;
                     border: 3px solid ${
                         color === selectedColor ? "#000" : "transparent"
                     };"
                     data-color="${color}">
                </div>`
                )
                .join("")}
          </div>

          <div style="display: flex; align-items: center; margin-top: 30px;">
            <span id="selectedColorLabel" style="margin-right: 10px; font-weight: bold;">Selected Color: ${selectedColor}</span>
            <input type="color" id="colorPicker" value="${selectedColor}" style="margin-right: 10px;">
          </div>
        </div>
      `,
                    showCancelButton: true,
                    confirmButtonText: route ? "Save" : "Create",
                    didOpen: () => {
                        const colorOptions =
                            document.querySelectorAll(".color-option");
                        const colorPicker =
                            document.getElementById("colorPicker");
                        const selectedColorLabel =
                            document.getElementById("selectedColorLabel");

                        // Handle basic color selection
                        colorOptions.forEach((el) => {
                            el.addEventListener("click", function () {
                                selectedColor = this.getAttribute("data-color");
                                colorPicker.value = selectedColor;
                                selectedColorLabel.innerText = `Selected Color: ${selectedColor}`;

                                // Update border for selected color
                                colorOptions.forEach(
                                    (opt) =>
                                        (opt.style.border =
                                            "3px solid transparent")
                                );
                                this.style.border = "3px solid #000";
                            });
                        });

                        // Handle manual color picker input
                        colorPicker.addEventListener("input", function () {
                            selectedColor = this.value;
                            selectedColorLabel.innerText = `Selected Color: ${selectedColor}`;

                            // Remove highlight if custom color is picked
                            colorOptions.forEach(
                                (opt) =>
                                    (opt.style.border = "3px solid transparent")
                            );
                        });
                    },
                    preConfirm: () => {
                        const routeName = document
                            .getElementById("routeName")
                            .value.trim();

                        if (!routeName) {
                            this.$swal.showValidationMessage(
                                "Line name cannot be empty!"
                            );
                            return false;
                        }

                        if (routeName.length < 3) {
                            this.$swal.showValidationMessage(
                                "Line name must be at least 3 characters!"
                            );
                            return false;
                        }

                        return { routeName, color: selectedColor };
                    },
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        const { routeName, color } = result.value;
                        if (route == null) {
                            this.$router.push({
                                name: "create-route",
                                params: {
                                    route_name: routeName,
                                    route_color: color,
                                },
                            });
                        } else {
                            this.$router.push({
                                name: "edit-route",
                                params: {
                                    route_id: route.id,
                                    new_route_name: routeName,
                                    new_route_color: color,
                                },
                            });
                        }
                    }
                });
        },

        viewRoute(route) {
            this.$router.push({
                name: "view-route",
                params: { route_id: route.id },
            });
        },
        editRoute(route) {
            this.$swal
                .fire({
                    title: "Enter route name",
                    input: "text",
                    inputValue: route.name,
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        const value = result.value.trim();
                        // this.$router.push({
                        //   name: "create-route",
                        //   params: { route_name: value ? value : "Untitled" },
                        // });
                        this.$router.push({
                            name: "edit-route",
                            params: {
                                route_id: route.id,
                                new_route_name: value ? value : "Untitled",
                            },
                        });
                    }
                });
        },
        deleteRoute(route, index) {
            this.$swal
                .fire({
                    title: "Delete route",
                    text:
                        "Are you sure to delete the route ' " +
                        route.name +
                        " ' ? You won't be able to revert this!",
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.deleteRouteServer(route.id, index);
                    }
                });
        },
        deleteRouteServer(route_id, index) {
            axios
                .delete(`/routes/${route_id}`)
                .then((response) => {
                    this.routes.splice(index, 1);
                    this.$notify({
                        title: "Success",
                        text: "Route deleted!",
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.$notify({
                        title: "Error",
                        text: "Error while deleting routes",
                        type: "error",
                    });
                    this.$swal("Error", error.response.data.message, "error");
                })
                .then(() => {
                    //this.isDeleting = false;
                });
        },
    },
};
</script>

<style scoped>
.color-option {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}
.color-option:hover {
    border: 2px solid #000;
}
</style>
